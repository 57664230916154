<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form
          label-width="80px"
          class="topS"
          ref="seekData"
          :model="seekData"
        >
          <el-row :gutter="10">
            <el-col :span="3">
              <el-form-item label="品牌">
                <el-select
                  v-model="seekData.brandId"
                  @change="selectBrand"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in brandSelectData"
                    :key="index"
                    :label="item.brandName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="门店">
                <el-select
                  v-model="seekData.pmsHotelId"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in hotelSelectData"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="状态">
                <el-select
                  v-model="seekData.status"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option value="checkin" label="入住">入住</el-option>
                  <el-option value="continued" label="续住">续住</el-option>
                  <el-option value="checkout" label="退房">退房</el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="房间号" prop="pmsRoomNumber">
                <el-input v-model="seekData.pmsRoomNumber" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="手机号" prop="phone">
                <el-input v-model="seekData.phone" clearable></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item label="时间" prop="time">
                <el-date-picker
                  v-model="seekData.time"
                  type="datetimerange"
                  :default-time="defaultTime"
                  :picker-options="pickerOptions"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  style="width: 150%"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
            @selection-change="tab"
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="pmsName" label="PMS供应商"></el-table-column>
            <el-table-column prop="hotelId" label="酒店编码">
              <template slot-scope="scope">
                <el-link
                  type="primary"
                  @click="openpopup(scope.row.hotelId, null)"
                  >{{ scope.row.hotelId }}</el-link
                >
              </template>
            </el-table-column>
            <el-table-column prop="roomId" label="房间号"></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.status === 'checkin'" type="success">入住</el-tag>
                <el-tag v-if="scope.row.status === 'continued'" type="warning">续住</el-tag>
                <el-tag v-if="scope.row.status === 'checkout'">退房</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="guestName"
              label="客人姓名"
            ></el-table-column>
            <el-table-column prop="phone" label="手机号"></el-table-column>
            <el-table-column prop="arr" label="入住时间"></el-table-column>
            <el-table-column prop="dep" label="到期时间"></el-table-column>
            <el-table-column prop="ct" label="推送时间"></el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openpopup(scope.row.hotelId, scope.row.roomId)"
                >
                  原始数据
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>

    <el-drawer title="原始数据" :visible.sync="drawer">
      <div class="right-box">
        <el-timeline>
          <el-timeline-item
            v-for="(data, index) in originData"
            :key="index"
            :timestamp="data.ct"
            placement="top"
          >
            <el-card>
              <pre>{{ JSON.stringify(data.pmsData, null, 4) }}</pre>
            </el-card>
          </el-timeline-item>
        </el-timeline>

        <el-pagination
          background
          layout="prev, pager, next, sizes, total, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="originSeekData.pageSize"
          :current-page="originSeekData.pageNum"
          :total="originDataTotal"
          @current-change="handleCurrentOrginDataChange"
          @size-change="handleSizeOrginDataChange"
        ></el-pagination>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import {
  getPmsDataList,
  getOriginPmsDataList,
  getHotelSelect,
  getBrandSelect,
} from "@/api";

export default {
  components: {},
  data() {
    return {
      height: 0,
      selectList: [],
      loading: false,
      // 品牌列表数据
      tableData: [],
      // 查询的数据
      total: null,
      seekData: {
        pmsHotelId: null,
        pmsRoomNumber: null,
        status: null,
        phone: null,
        time: null,
        pageSize: 10,
        pageNum: 1,
      },
      query: {},
      brandSelectData: null,
      hotelSelectData: null,
      originData: null,
      originDataTotal: null,
      drawer: false,
      reverse: true,
      originSeekData: {
        pmsHotelId: null,
        pmsRoomNumber: null,
        pageSize: 10,
        pageNum: 1,
      },
      defaultTime: ["00:00:00", "23:59:59"],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },

  computed: {},
  created() {
    // 调用初始化表格数据方法
    this.initialize();
    //this.getList();
  },
  methods: {
    initialize() {
      getBrandSelect().then((res) => {
        if (res.data.data) {
          this.brandSelectData = res.data.data;
        }
      });
    },
    selectBrand(id) {
      getHotelSelect(id).then((res) => {
        if (res.data.data) {
          this.seekData.pmsHotelId = null;
          this.hotelSelectData = res.data.data;
        }
      });
    },
    tab(list) {
      this.selectList = list;
    },
    openpopup(hotelId, roomId) {
      let that = this;
      (that.originSeekData.pmsHotelId = hotelId),
        (that.originSeekData.pmsRoomNumber = roomId),
        (that.drawer = true);

      this.getOriginList();
    },

    getOriginList() {
      let param = {
        pmsHotelId: this.originSeekData.pmsHotelId,
        pmsRoomNumber: this.originSeekData.pmsRoomNumber,
        pageNum: this.originSeekData.pageNum,
        pageSize: this.originSeekData.pageSize,
      };

      getOriginPmsDataList(param).then((res) => {
        if (res.data.data) {
          this.originData = res.data.data.rows;
          this.originDataTotal = res.data.data.total;
        } else {
          this.originData = [];
          this.originDataTotal = 0;
        }
      });
    },

    // 初始化表格数据
    getList() {
      this.loading = true;
      getPmsDataList(this.seekData)
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    showStatus(val) {
      if (val == "checkin") {
        return "入住";
      } else if (val == "checkout") {
        return "退房";
      } else if (val == "continued") {
        return "续住";
      }
      return val;
    },
    bindViewClose() {
      this.getList();
    },
    // 查询按钮
    handleSearch() {
      this.seekData.pageNum = 1;
      this.query = Object.assign({}, this.seekData);
      this.getList();
    },
    // 重置按钮
    reset() {
      this.seekData.pageNum = 1;
      this.$refs.seekData.resetFields();
      this.handleSearch();
    },
    // 列表分页
    handleCurrentChange(pageNum) {
      this.seekData.pageNum = pageNum;
      this.getList();
    },
    handleSizeChange(psize) {
      this.seekData.pageSize = psize;
      this.seekData.pageNum = 1;
      this.getList();
    },
    // 原始数据分页
    handleCurrentOrginDataChange(pageNum) {
      this.originSeekData.pageNum = pageNum;
      this.getOriginList();
    },
    handleSizeOrginDataChange(psize) {
      this.originSeekData.pageSize = psize;
      this.originSeekData.pageNum = 1;
      this.getOriginList();
    },
  },
};
</script>
<style lang="scss" scoped>
.right-box {
  padding-left: 20px;
}
</style>
